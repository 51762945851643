export enum errorMessages {
  NAME_EMPTY = 'Enter your name.',
  NAME_MIN_LENGTH = 'Name must be 2 or more characters.',
  EMAIL_EMPTY = 'Enter your email address.',
  EMAIL_INVALID = 'Enter a valid email address',
  EMAIL_INVALID_LONG = 'Invalid email address. Double check and try again.',
  PASSWORD_CONFIRMATION_MISMATCH = 'Password must match',
  PASSWORD_EMPTY = 'Password is required',
  PASSWORD_MIN_LENGTH = 'Should be at least 8 characters',
  PHONE_EMPTY = 'Enter your phone number.',
  PHONE_INVALID = 'Invalid phone number. Double check and try again.',
  PHONE_MIN_LENGTH = 'Invalid phone number. Please enter 10 digits.',
  DATE_EMPTY = 'Enter a valid date (mm/dd/yyyy).',
  DATE_EMPTY_MOBILE = 'Enter a valid date.',
  DATE_INVALID_IN_PAST = 'Enter a date in the future.',
  DATE_INVALID_BEFORE_TARGET = 'Enter a future date.',
  FIRST_NAME_EMPTY = 'First name is required',
  LAST_NAME_EMPTY = 'Last name is required',
  MOVE_IN_AFTER_TOUR_DATE = 'Move in date must be on or after tour date.',
}
