import { ReactComponent as CloseIcon } from '@brand/icons/close.svg'
import {
  Dialog as DialogCore,
  Transition,
  type TransitionEvents,
} from '@headlessui/react'
import { useAtom, useSetAtom } from 'jotai'
import type { ReactElement, ReactNode } from 'react'
import React, {
  useRef,
  cloneElement,
  Fragment,
  useCallback,
  useEffect,
  forwardRef,
} from 'react'
import { BackDrop } from '../../components/back-drop/back-drop'
import { IconButton } from '../../components/icon-button/icon-button'
import type { DataAttributes } from '../../types'
import { MODAL_CLOSE_TRANSITION_DURATION } from './modal.const'
import styles from './modal.module.css'
import { modalOpenAtom } from './modal.store'
import backdropStyles from '../../components/back-drop/back-drop.module.css'

export type ModalProps = {
  open?: boolean
  onClose?: () => void
  afterEnter?: TransitionEvents['afterEnter']
  children: ReactNode
  className?: string
  entranceStyle?: 'grow' | 'bottomSlide'
  leaveStyle?: 'shrinkFade' | 'fade'
  /**
   * Whether the modal and it's backdrop should close when the back drop is clicked
   *
   * Defaults to: "true"
   * */
  closeOnBackDropClick?: boolean
} & DataAttributes

const modalTransitionStylePrefixMap = {
  grow: 'modalGrow',
  bottomSlide: 'modalBottomSlide',
  shrinkFade: 'modalShrinkFade',
  fade: 'modalFade',
}

const Modal = forwardRef<HTMLDivElement, ModalProps>(function Modal(
  {
    closeOnBackDropClick = true,
    entranceStyle = 'grow',
    leaveStyle = 'shrinkFade',
    ...props
  }: ModalProps,
  forwardedRef
) {
  const panelRef = useRef<HTMLDivElement>(null)

  const [open, setOpen] = useAtom(modalOpenAtom)

  const handleClose = useCallback(() => {
    setOpen(false)
    setTimeout(() => {
      props.onClose?.()
    }, MODAL_CLOSE_TRANSITION_DURATION)
  }, [props, setOpen])

  useEffect(() => {
    if (typeof props.open === 'undefined') {
      setOpen(true)
    }
  }, [props.open, setOpen])

  return (
    <Transition show={open} as={Fragment}>
      <DialogCore
        // ensures the modal is closed when you press Escape
        onClose={handleClose}
        className={props.className}
        data-tag_section={props['data-tag_section']}
        data-tag_item={props['data-tag_item']}
        data-tid={props['data-tid']}
      >
        <Transition.Child
          as={Fragment}
          enter={backdropStyles.backDropEnter}
          enterFrom={backdropStyles.backDropEnterFrom}
          enterTo={backdropStyles.backDropEnterTo}
          leave={backdropStyles.backDropLeave}
          leaveFrom={backdropStyles.backDropLeaveFrom}
          leaveTo={backdropStyles.backDropLeaveTo}
          afterEnter={props.afterEnter}
        >
          <BackDrop isVisible />
        </Transition.Child>
        <div className={styles.container}>
          <div
            className={styles.panelWrap}
            ref={panelRef}
            data-tag_item={
              closeOnBackDropClick ? 'close_overlay' : 'modal_overlay'
            }
            data-tid="modal"
            // ensures the modal is closed when you click the backdrop, if necessary
            onClick={(e) => {
              if (closeOnBackDropClick && e.target === panelRef.current) {
                handleClose()
              }
            }}
          >
            <Transition.Child
              as={Fragment}
              enter={
                styles[`${modalTransitionStylePrefixMap[entranceStyle]}Enter`]
              }
              enterFrom={
                styles[
                  `${modalTransitionStylePrefixMap[entranceStyle]}EnterFrom`
                ]
              }
              enterTo={
                styles[`${modalTransitionStylePrefixMap[entranceStyle]}EnterTo`]
              }
              leave={
                styles[`${modalTransitionStylePrefixMap[leaveStyle]}Leave`]
              }
              leaveFrom={
                styles[`${modalTransitionStylePrefixMap[leaveStyle]}LeaveFrom`]
              }
              leaveTo={
                styles[`${modalTransitionStylePrefixMap[leaveStyle]}LeaveTo`]
              }
            >
              <div className={styles.panel} ref={forwardedRef}>
                {props.children}
              </div>
            </Transition.Child>
          </div>
        </div>
      </DialogCore>
    </Transition>
  )
})

function CloseButton(
  props: Pick<ModalProps, 'onClose'> & {
    children?: ReactNode
    closeModalOnClick?: boolean
    ['data-tag_item']?: string
  }
) {
  const setOpen = useSetAtom(modalOpenAtom)
  const handleClose = useCallback(() => {
    if (props.closeModalOnClick !== false) {
      setOpen(false)
    }
    setTimeout(() => {
      props.onClose?.()
    }, MODAL_CLOSE_TRANSITION_DURATION)
  }, [props, setOpen])

  return (
    <span
      className={styles.closeButton}
      data-tag_item={props['data-tag_item'] || 'x'}
    >
      {props.children ? (
        cloneElement(props.children as ReactElement, {
          ['data-tid']: 'close-modal',
          onClick: handleClose,
        })
      ) : (
        <IconButton
          data-tid="close-modal"
          onClick={handleClose}
          icon={<CloseIcon />}
          label="Close"
        />
      )}
    </span>
  )
}

CloseButton.displayName = 'CloseButton'

const ModalNamespace = Object.assign(Modal, { CloseButton })
export { ModalNamespace as Modal }
