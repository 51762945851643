/* eslint-disable react/jsx-props-no-spreading */
import type {
  PolymorphicForwardRefExoticComponent,
  PolymorphicPropsWithoutRef,
} from '@rentpath/core-components'
import clsx from 'clsx'
import type { ElementType, ForwardedRef, ReactNode } from 'react'
import React, { forwardRef } from 'react'
import type { DataAttributes, LightDarkVariant } from '../../types'

import styles from './anchor.module.css'

type AnchorOwnProps = {
  children?: ReactNode
  variant?: LightDarkVariant
} & DataAttributes

const AnchorDefaultElement = 'a'

export const Anchor: PolymorphicForwardRefExoticComponent<
  AnchorOwnProps,
  typeof AnchorDefaultElement
> = forwardRef(function Anchor<
  T extends ElementType = typeof AnchorDefaultElement
>(
  { as, variant, ...restProps }: PolymorphicPropsWithoutRef<AnchorOwnProps, T>,
  ref: ForwardedRef<Element>
) {
  const Element: ElementType = as || AnchorDefaultElement

  return (
    <Element
      ref={ref}
      {...restProps}
      className={clsx(
        styles.anchor,
        variant === 'dark' && styles.onDark,
        restProps.className
      )}
    />
  )
})
