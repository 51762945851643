export const PASSWORD_MIN_LENGTH = 8

export const NAME_MIN_LENGTH = 2

export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

// Regular expression to verify phone number contains only the characters
// we want to allow in the field: digits, whitespace, certain special chars,
// but no alphanumeric characters.
const PHONE_REGEX = /^[\d\s()+,.\-_]+$/

export const isValidEmail = (email: string): boolean => EMAIL_REGEX.test(email)

// Helper function to determine if a field has some kind of input
export function hasNonSpace(str: string): boolean {
  return /\S/.test(str)
}

// Helper function to strip out non-digits from the phone number
export function cleanPhone(phone: string): string {
  return phone.replace(/\D/g, '')
}

/**
 * Helper function for phone input validation.
 * We will eventually strip out everything but the digits in any case,
 * but for validation we will allow certain characters like ()
 * because users might tend to use those.
 * @param {string} phone - Phone Number to clean up
 * @returns {string} Only numbers, no non-digits
 */
export function isValidPhone(phone: string): boolean {
  const digitsOnly = cleanPhone(phone)
  const firstDigit = digitsOnly[0]

  return (
    PHONE_REGEX.test(phone) &&
    digitsOnly.length === 10 &&
    !['0', '1'].includes(firstDigit)
  )
}
