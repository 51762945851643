import { errorMessages } from './error-messages'
import { isValidEmail, isValidPhone } from './utils'

export function validateEmail(value: string) {
  if (!value) {
    return errorMessages.EMAIL_EMPTY
  }

  return isValidEmail(value) || errorMessages.EMAIL_INVALID
}

/**
 * @params value the date (YYYY-MM-DD) to validate
 * @params isMobile whether the current view is for mobile
 * @params minDate if supplied, will validate the value arg is after this date (YYYY-MM-DD)
 * @params minDateError optional error message to show if minDate fails validation
 */
export function validateDate(
  value: string,
  isMobile: boolean,
  minDate?: string,
  minDateError?: errorMessages
) {
  if (!value) {
    return isMobile ? errorMessages.DATE_EMPTY_MOBILE : errorMessages.DATE_EMPTY
  }

  /**
   * If minDate is supplied, use this for comparison. Else, create a date in the browser's locale
   * for today at 00:00 so we can verify the date chosen is not before today.
   */
  const comparisonDate = minDate ? new Date(minDate) : new Date()
  comparisonDate.setHours(0, 0, 0, 0)

  /**
   * From the date string "yyyy-mm-dd" create a date object for comparison.
   * If we just do `new Date("yyyy-mm-dd")`, the date will be modified using the browser's timezone
   * offset. But if we do `new Date(y, m, d)`, it will not be modified.
   */
  const [yyyy, mm, dd] = value.split('-')
  const d = new Date(
    parseInt(yyyy, 10),
    parseInt(mm, 10) - 1, // month starts at zero, so subtract one
    parseInt(dd, 10)
  )

  const isDateBeforeTarget = Boolean(d < comparisonDate)

  if (isDateBeforeTarget) {
    if (minDate) {
      return minDateError || errorMessages.DATE_INVALID_BEFORE_TARGET
    }

    return errorMessages.DATE_INVALID_IN_PAST
  }

  return true
}

export function validatePhone(value: string | undefined, isRequired: boolean) {
  if (value?.length === 0 && isRequired) {
    return errorMessages.PHONE_EMPTY
  }

  if (value && value.length > 0 && !isValidPhone(value)) {
    const isPhoneTenDigits = value.replace(/\D/g, '').length === 10 // regex strips non-digits

    return isPhoneTenDigits
      ? errorMessages.PHONE_INVALID
      : errorMessages.PHONE_MIN_LENGTH
  } else {
    return true
  }
}
