import {
  MLS_DISCLOSURES_URL,
  PRIVACY_POLICY_URL,
  TERMS_OF_SERVICE_URL,
} from '@brand/slots/footer/get-footer-links'
import { Anchor } from '../../components/anchor/anchor'
import type { RentpathTermProps } from './rent-terms'

export function RentTermsLinks({
  colorScheme,
  termsTagId,
  privacyPolicyTagId,
}: Pick<
  RentpathTermProps,
  'colorScheme' | 'termsTagId' | 'privacyPolicyTagId'
>) {
  return (
    <>
      <Anchor
        href={TERMS_OF_SERVICE_URL}
        target="_blank"
        rel="noreferrer"
        variant={colorScheme}
        data-tag_item={termsTagId}
      >
        {' '}
        Terms of Service
      </Anchor>
      ,{' '}
      <Anchor
        href={PRIVACY_POLICY_URL}
        target="_blank"
        rel="noreferrer"
        data-tag_item={privacyPolicyTagId}
        variant={colorScheme}
      >
        Privacy Policy
      </Anchor>{' '}
      and{' '}
      <Anchor
        href={MLS_DISCLOSURES_URL}
        target="_blank"
        rel="noreferrer"
        data-tag_item="mls_disclosures"
      >
        MLS Disclosures
      </Anchor>
    </>
  )
}
