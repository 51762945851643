import { forwardRef } from 'react'
import type { InputProps } from '../input/input'
import { Input } from '../input/input'

export const EmailInput = forwardRef<
  HTMLInputElement,
  Omit<InputProps, 'label'> & { label?: InputProps['label'] }
>((props, ref) => (
  <Input autoComplete="email" label="Email" type="email" {...props} ref={ref} />
))

EmailInput.displayName = 'EmailInput'
