import type {
  PolymorphicForwardRefExoticComponent,
  PolymorphicPropsWithoutRef,
} from '@rentpath/core-components'
import clsx from 'clsx'
import type {
  ChangeEvent,
  ElementType,
  FocusEvent,
  ForwardedRef,
  ReactNode,
} from 'react'
import React, { forwardRef, useEffect, useId, useState } from 'react'
import type { DataAttributes } from '../../types'
import styles from './input.module.css'

type InputOwnProps = {
  label: string
  error?: string
  prefix?: ReactNode
  value?: string
  placeholder?: string
  type?: string
  required?: boolean
  hideAsterisk?: boolean
} & DataAttributes

export const InputDefaultElement = 'input'

export type InputProps<T extends ElementType = typeof InputDefaultElement> =
  PolymorphicPropsWithoutRef<InputOwnProps, T>

export const Input: PolymorphicForwardRefExoticComponent<
  InputOwnProps,
  typeof InputDefaultElement
> = forwardRef(function Input<
  T extends ElementType = typeof InputDefaultElement
>(
  {
    as,
    label,
    prefix,
    error,
    onBlur,
    onFocus,
    onChange,
    required,
    hideAsterisk,
    ...restProps
  }: InputProps<T>,
  ref: ForwardedRef<HTMLInputElement>
) {
  const Element: ElementType = as || InputDefaultElement
  const id = useId()
  const [value, setValue] = useState(() => restProps.value || '')
  const [isFocused, setIsFocused] = useState(false)
  const [isDirty, setIsDirty] = useState(false)
  const shouldFocusLabel =
    isFocused ||
    value.length > 0 ||
    !!restProps.placeholder ||
    restProps.type === 'date'

  useEffect(() => {
    if (typeof restProps.value === 'string' && restProps.value !== value) {
      setValue(restProps.value)
    }
  }, [restProps.value, value])

  function handleBlur(e: FocusEvent<HTMLInputElement>) {
    onBlur?.(e)
    setIsFocused(false)
  }

  function handleFocus(e: FocusEvent<HTMLInputElement>) {
    onFocus?.(e)
    setIsDirty(true)
    setIsFocused(true)
  }

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    onChange?.(e)
    setValue(e.target.value)
  }

  return (
    <div className={restProps.className}>
      <label
        htmlFor={id}
        className={clsx(styles.wrapperStyles, error && styles.inputWithError)}
        aria-label={label}
      >
        {prefix && <span className={styles.inputPrefix}>{prefix}</span>}

        <Element
          {...restProps}
          aria-invalid={error ? 'true' : 'false'}
          value={value}
          id={id}
          ref={ref}
          onBlur={handleBlur}
          onFocus={handleFocus}
          onChange={handleChange}
          className={clsx(styles.input, {
            [styles.inputWithPrefix]: Boolean(prefix),
            [styles.textarea]: as === 'textarea',
          })}
          required={required}
        />

        {!prefix && label && (
          <span
            className={clsx(styles.lineStylesDefault, {
              [styles.lineStyles]: !shouldFocusLabel,
              [styles.lineStylesFocused]: shouldFocusLabel,
              [styles.lineStylesWithError]: error && shouldFocusLabel,
              [styles.lineTransitionStyles]: isDirty,
            })}
          >
            {`${label}${required && !hideAsterisk ? ' *' : ''}`}
          </span>
        )}
      </label>

      {error && (
        <p
          role="alert"
          data-tid={
            restProps['data-tid']
              ? `${restProps['data-tid']}-message`
              : undefined
          }
          className={styles.error}
        >
          {error}
        </p>
      )}
    </div>
  )
})
