import { list } from '../../config/shared-links'

const SOLUTIONS_PATH = 'https://solutions.rent.com'
export const ABOUT_US_URL = `${SOLUTIONS_PATH}/about/`
export const CONTACT_US_URL = `${SOLUTIONS_PATH}/contact/`
export const PRESS_AND_MEDIA = `${SOLUTIONS_PATH}/contact/`
export const DO_NOT_SELL_URL = `${SOLUTIONS_PATH}/do-not-sell/`
export const ANDROID_APP_URL =
  'https://play.google.com/store/apps/details?id=com.primedia.apartmentguide&pli=1'
export const IOS_APP_URL = 'https://apps.apple.com/app/apartments/id292234839'
export const TERMS_OF_SERVICE_URL = `${SOLUTIONS_PATH}/terms-of-use/`
export const PRIVACY_POLICY_URL = `${SOLUTIONS_PATH}/privacy-policy/`
export const MLS_DISCLOSURES_URL = `${SOLUTIONS_PATH}/mls-disclosures/`
export const FAIR_HOUSING_POLICY_URL = `${SOLUTIONS_PATH}/equal-housing-opportunity/`
export const NEW_YORK_STATE_FAIR_HOUSING_NOTICE_URL =
  'https://dos.ny.gov/system/files/documents/2024/06/nys-housing-and-anti-discrimination-notice_06.2024.pdf'
const REDFIN_LINK = 'https://www.redfin.com'

export const agFooterLinks = [
  {
    heading: 'Our Company',
    links: [
      {
        tag: 'about_us',
        text: 'About Us',
        url: ABOUT_US_URL,
      },
      {
        tag: 'press_and_media',
        text: 'Press & Media',
        url: PRESS_AND_MEDIA,
      },
      {
        tag: 'redfin',
        text: 'Redfin.com',
        url: REDFIN_LINK,
      },
      {
        tag: 'careers',
        text: 'Careers',
        url: 'https://solutions.rent.com/careers/',
      },
      {
        tag: 'california_privacy',
        text: 'Do Not Sell My Info',
        url: DO_NOT_SELL_URL,
      },
    ],
    'data-tid': 'our-company',
    id: 'our-company',
  },
  {
    heading: 'Let Us Help',
    'data-tid': 'let-us-help',
    id: 'let-us-help',
    links: [
      {
        tag: 'avoid_scams',
        text: 'Avoid Scams',
        url: '/company/security/',
      },
      {
        tag: 'list_your_community',
        text: list.TEXT,
        url: list.URL,
      },
      {
        tag: 'sitemap',
        text: 'Site Map',
        url: '/sitemap/',
      },
      {
        tag: 'accessibility',
        text: 'Accessibility',
        url: `${SOLUTIONS_PATH}/accessibility/`,
        target: '_blank',
        nofollow: true,
      },
    ],
  },
]
